export const Options = {




        method: 'GET',
        headers: {
            'X-RapidAPI-Key': '7103bc01ffmsh0015f63447d9ee6p1fd9c8jsn9269fe6a285c',
            'X-RapidAPI-Host': 'deezerdevs-deezer.p.rapidapi.com'
        }

    


};
